/*make sure to import all the other css files here for them to work in the project*/

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .underline-space-below {
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
    display: inline-block;
  }
}

@layer base {
  @font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins/Poppins-Medium.ttf");
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
  }
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
  }
  @font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins/Poppins-Bold.ttf");
  }
  @font-face {
    font-family: "Inter-Bold";
    src: url("../fonts/Inter/Inter-Bold.ttf");
  }
  @font-face {
    font-family: "Inter-Medium";
    src: url("../fonts/Inter/Inter-Medium.ttf");
  }
  @font-face {
    font-family: "Inter-Regular";
    src: url("../fonts/Inter/Inter-Regular.ttf");
  }
  @font-face {
    font-family: "Inter-SemiBold";
    src: url("../fonts/Inter/Inter-SemiBold.ttf");
  }
  @font-face {
    font-family: "Inter-Italic";
    src: url("../fonts/Inter/Inter-Italic.ttf");
  }
}

.custom-translate {
  transform: translate(-50%, -50%);
}
.calc-screen-min-height {
  min-height: calc(100vh - 180px);
}

.hideScrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hideScrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background-color: #edf2f7;
}
.private-scroll-x::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Scrollbar thumb */
.private-scroll-x::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

.private-scroll-x::-moz-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 3px;
}

/* Scrollbar track */
.private-scroll-x::-webkit-scrollbar-track {
  background-color: #edf2f7;
}

.private-scroll-x::-moz-scrollbar-track {
  background-color: #edf2f7;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-input {
  height: 33.39px;
  width: 200px;
  padding: 2px 4px;
  border: 0.5px solid #ccc;
  border-radius: 20px;
  outline: none;
}

.search-input:focus {
  border-color: #666;
}

.search-input:not(:focus) {
  display: none;
}

.bg-gradient-black {
  background: linear-gradient(to bottom, #313860 0%, #151928 100%);
}
.custom-scrollbar-container ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.custom-scrollbar-container ::-webkit-scrollbar-thumb:horizontal {
  border-radius: 5px;
}

.custom-scrollbar-container ::-webkit-scrollbar-thumb:vertical {
  border-radius: 5px;
}

.custom-scrollbar-container ::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
}

.custom-scrollbar-container ::-webkit-scrollbar-thumb {
  background: #a0aec0;
}

.custom-scrollbar-container ::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
/* Hide the number input arrows in webkit browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the number input arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.truncate-google-text-project {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  max-height: 100px;
  line-height: 20px;
}
.truncate-google-text-ad-library {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
  max-height: 150px;
  line-height: 20px;
}
.truncate-google-text-ad-library-popup {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 13;
  max-height: 270px;
  line-height: 20px;
}
/* Hide the scrollbar but keep the scroll functionality */
#scroll-bar-unsplash::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#scroll-bar-unsplash {
  /* Fallback for browsers that don't support the pseudo-element */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar track */
#scroll-bar-unsplash::-webkit-scrollbar-track {
  background: transparent;
}

/* Hide scrollbar thumb */
#scroll-bar-unsplash::-webkit-scrollbar-thumb {
  background: transparent;
}
